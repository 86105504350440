<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton :showBtn="false" :showAddNew="false" btnTitle="Add New" title="Voucher List"
                @onClickAddNewButton="onClickAddNewButton" />

            <div class="row match-height mt-1">
                <DateQuerySetter @onClickGo="getPayments">

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Voucher type: </label>
                        <select class="form-control" v-model="voucher_type">
                            <option :value="null" selected>Select voucher type</option>
                            <option v-for="voucherType in Object.keys(voucherTypes)" :value="voucherType"
                                :key="voucherType">
                                {{ voucherTypes[voucherType] }}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Txn type: </label>
                        <select class="form-control" v-model="txn_type">
                            <option :value="null" selected>Select txn type</option>
                            <option v-for="txnType in Object.keys(txnTypes)" :value="txnType" :key="txnType">
                                {{ txnTypes[txnType] }}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select placeholder="Select Business" class="w-100" :options="businesses" label="name"
                            :reduce="(name) => name.id" v-model="business_id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Cost Center: </label>
                        <v-select placeholder="Select Cost Centre" v-model="cost_centre_id" :options="costCentres"
                            label="name" :reduce="name => name.id" />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Project</label>
                        <v-select placeholder="Select Project" v-model="project_id" :options="projects" label="name"
                            :reduce="name => name.id" />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Created By: </label>
                        <v-select placeholder="Created By" class="w-100" :options="contactList" label="name"
                            :reduce="(name) => name.id" v-model="created_by" />
                    </div>
                </DateQuerySetter>
            </div>
        </div>

        <ListTable />
        <div class="mb-2"></div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination" />
        </div>
        <Loader v-if="loading" />
    </div>
</template>

<script>
import handleJournal from '@/services/modules/journal'
import handleReport from "@/services/modules/accountingReport"; 
import { inject, ref } from 'vue'
import { computed } from 'vue'
import { mapMutations } from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/ngo/vouchar/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import handleProjects from "@/services/modules/procurement/project";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import handleCBusinesses from "@/services/modules/businesses";

import handleCostCentres from "@/services/modules/ngo/costCentre";

export default {
    name: 'VoucharList',

    data: () => ({
        offset: 20,
        contactList: [],
        accountHeads: [],
        businesses: [],
        projects: [],
        voucherTypes: {},
        txnTypes: {},
        costCentres: [],

        contact_profile_id: null,
        voucher_type: null,
        txn_type: null,
        cost_centre_id: null,
        created_by: null,
        account_head_id: null,
        business_id: null,
        project_id: null,
    }),

    components: {
        TitleButton,
        DateQuerySetter,
        ListTable,
        Pagination,
        Loader
    },
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        page() {
            return this.$route.query.page
        },
    },
    methods: {
        ...mapMutations({
            setJournals: 'journal/setJournals',
            setPaginationData: 'setPaginationData',
            resetPagination: 'resetPagination'
        }),
        onClickAddNewButton() {
            this.$router.push({
                name: 'ngo-payment-voucher-create',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            })
        },

        async getPayments() {
            const query = this.getQuery()
            // return;
            try {
                this.loading = true
                let res = await this.fetchJournals(query)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.setJournals(res.data.data)
                    if (res.data.data.length > 0) {
                        this.setPagination(res.data)
                    }
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        setPagination(data) {
            this.setPaginationData({
                records: data.total,
                perPage: data.per_page,
                currentPage: data.current_page,
            })
        },

        onPageChange(page) {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({ path: this.$route.path, query: routeQuery })
            setTimeout(() => {
                this.getPayments()
            }, 100)
        },

        getQuery() {
            let query = '?company_id=' + this.companyId
            if (!this.start && !this.end) return query
            if (this.start) query += '&start_date=' + this.start
            if (this.end) query += '&end_date=' + this.end
            query += '&offset=' + this.offset
            if (this.page) query += '&page=' + this.page
            if (this.contact_profile_id) query += "&contact_profile_id=" + this.contact_profile_id;
            if (this.account_head_id) query += "&account_head_id=" + this.account_head_id;
            if (this.business_id) query += "&business_id=" + this.business_id;
            if (this.project_id) query += "&project_id=" + this.project_id;
            if (this.voucher_type) query += "&voucher_type=" + this.voucher_type;
            if (this.txn_type) query += "&txn_type=" + this.txn_type;
            if (this.cost_centre_id) query += "&cost_centre_id=" + this.cost_centre_id;
            if (this.created_by) query += "&created_by=" + this.created_by;
            query += '&all_vouchers=yes'

            return query
        },
    },

    setup() {
        const showError = inject('showError');
        const showSuccess = inject('showSuccess');
        // const store = useStore()

        const { fetchProjects } = handleProjects()

        const { fetchBusinessList } = handleCBusinesses()

        const { fetchCostCentreList } = handleCostCentres();
        const { fetchContacts } = handleImportTracker();

        const { fetchHome } = handleReport();

        const {
            fetchJournals,
            loading,
            fetchAccountHeads
        } = handleJournal()
        return {
            journals: computed(() => store.state.journal.journals),
            fetchCostCentreList,
            fetchContacts,
            fetchHome,
            fetchJournals,
            loading,
            showError,
            showSuccess,
            fetchAccountHeads,
            fetchProjects,
            fetchBusinessList
        }
    },

    async mounted() {
        this.loading = true;
        let companyId = this.$route.params.companyId
        let contactId = '';
        let q = '';
        let companyQuery = '?company_id=' + companyId;
        let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
        let projectQuery = companyQuery + '&with_donors=1';

        await this.getPayments()
        Promise.all([
            this.fetchContacts(companyQuery).then(res => {
                if (res.data) {
                    this.contactList = res.data;
                }
            }),
            this.fetchCostCentreList(companyQuery).then(res => {
                if (res.data) {
                    this.costCentres = res.data
                }
            }),
            this.fetchHome().then(res => {
                if (res.status) {
                    this.voucherTypes = res.data.voucher_type;
                    this.txnTypes = res.data.txn_type;
                }
            }),
            this.fetchAccountHeads(companyId).then(res => {
                if (res.data) {
                    this.accountHeads = res.data
                }
            }),
            this.fetchProjects(projectQuery).then(res => {
                if (res.data) {
                    this.projects = res.data
                }
            }),
            this.fetchBusinessList(companyQuery).then(res => {
                if (res.data) {
                    this.businesses = res.data;
                }
            }),
        ]).then(() => {
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            this.showError(err.message)
        }).finally(() => {
            this.loading = false;
        })
    }
}
</script>
